<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>盘中异动</el-breadcrumb-item>
      </el-breadcrumb>

    </div>

    <el-card shadow="always" class="list-container">
      <div style="display:flex;align-items:center;marginBottom:10px">
        <div style="width:100px;">数据日期</div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in dateList" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="header">
        <div class="header__title">数据列表</div>
        <div class="header__btn" @click="addOrEdit('add')">
          + 手动添加异动
        </div>
      </div>
      <div class="content">
        <el-table :data="tableData" :header-cell-style="{
            'text-align': 'center',
            'font-weight': 'bold',
            color: '#333',
          }" :cell-style="{ 'text-align': 'center', color: '#333' }" style="width: 100%">
          <el-table-column type="index" label="序号" width="100" />
          <el-table-column prop="datetime" label="异动时间">
            <template #default="scope">
              <text v-if="scope.row.datetime">{{scope.row.datetime.slice(11,16)}}</text>
            </template>
          </el-table-column>
          <el-table-column prop="content" label="异动内容" />
          <el-table-column prop="reason" label="异动原因" />
          <el-table-column prop="isImportant" label="是否重要">
            <template #default="scope">
              <span v-show="scope.row.isImportant === '0'">否</span>
              <span v-show="scope.row.isImportant === '1'">是</span>
            </template>
          </el-table-column>
          <el-table-column prop="reasonAddTime" label="原因添加时间" />
          <el-table-column prop="updateMan" label="添加人" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button @click="addOrEdit('edit',scope.row)" type="text" size="small">编辑</el-button>
              <el-button @click="deleteOperate(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination v-model:currentPage="currentPage" :page-sizes="[5, 10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog :title="title" v-model="dialogVisible" :width="520">
      <el-form ref="form" label-position="left" :model="formList" :rules='rules' label-width="120px" class="stock-form">
        <el-form-item label="异动时间" prop='editable'>
          <div class="stockInfo" style="width: 450px">
            <el-time-picker style="width: 280px" :editable='editable' v-model="formList.datetime" placeholder="选择时间" />
          </div>
        </el-form-item>
        <el-form-item label="异动内容" prop='content'>
          <div class="stockInfo" style="width: 450px">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 10}" v-model.trim="formList.content" maxlength="400" style="width: 280px"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="异动原因">
          <div class="stockInfo" style="width: 450px">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 10}" v-model.trim="formList.reason" maxlength="400" style="width: 280px"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="是否重要" prop='isImportant'>
          <el-select v-model="formList.isImportant" placeholder="请选择" style="width: 100%">
            <el-option v-for="item in isImportantList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="info" @click="confirmNode" style="background-color:#38a28a">提 交</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted, watch, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  setup() {
    const form = ref()
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      policyTitle: "",
      publishDate: [],
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      total: 0,
      dialogVisible: false,
      content: "",
      id: '',
      datetime: "",
      isImportant: "",
      reason: "",
      options: [],
      isImportantList: [{ value: '1', label: '是' }, { value: '0', label: '否' }],
      value: "",
      stockState: "",
      title: '',
      editable: false,
      dateList: ['2022-12-01'],
      formList: {
        content: "",
        id: '',
        datetime: "",
        isImportant: "",
        reason: "",
      },
      rules: {
        editable: [
          {
            required: true,
            message: " ",
            trigger: "change",
          },
        ],
        content: [
          { required: true, message: "请填写异动内容", trigger: "blur" },
        ],
        isImportant: [
          { required: true, message: "请选择", trigger: "change" },
        ]
      },
    });
    onMounted(() => {
      //   getList();
      getDateList();
    });
    // 获取日期列表
    const getDateList = () => {
      proxy.$get('/cts/back/preOrder/dateList').then((res) => {
        if (res.resultState === '200') {
          state.dateList = res.data
          state.value = res.data[0]
        }
      }).catch((err) => {

      });
    }
    const getList = () => {
      let params = {
        pageNo: state.currentPage,
        pageSize: state.pageSize
      }
      proxy.$post("/cts/back/trading/getUnusual", params).then((res) => {
        if (res.resultState === "200") {
          state.tableData = res.data.list;
          state.total = res.data.total;
        }
      })
        .catch((err) => { });
    };
    getList()
    const addOrEdit = (val, detail) => {
      state.dialogVisible = true
      state.title = val === 'add' ? '新增异动信息' : '编辑异动信息';

      if (val === 'edit') {
        nextTick(() => {
          form.value.resetFields();
          state.formList.content = detail.content;
          state.formList.datetime = detail.datetime;
          state.formList.id = detail.id;
          state.formList.isImportant = detail.isImportant;
          state.formList.reason = detail.reason;
        })

      } else {
        nextTick(() => {
          form.value.resetFields();
        })
        state.formList.content = "";
        state.formList.datetime = "";
        state.formList.isImportant = "";
        state.formList.reason = "";
      }

    };
    const handleSizeChange = (val) => {
      state.pageSize = val;
      getList();
    };
    const handleCurrentChange = (val) => {
      state.currentPage = val;
      getList();
    };
    // 封装时间
    const formatTime = (date) => {
      //   let y = date.getFullYear(); // 年
      //   y = y < 10 ? ('0' + y) : y; // 不足十位数补零
      //   let m = date.getMonth() + 1; // 月
      //   m = m < 10 ? ('0' + m) : m;
      //   let d = date.getDate(); // 日
      //   d = d < 10 ? ('0' + d) : d;
      let h = date.getHours(); // 时
      h = h < 10 ? ('0' + h) : h;
      let minut = date.getMinutes(); // 分
      minut = minut < 10 ? ('0' + minut) : minut;
      let des = date.getSeconds(); // 秒
      des = des < 10 ? ('0' + des) : des;

      return h + ':' + minut + ':' + des;
    }

    const confirmNode = () => {
      if (!state.formList.datetime || !state.formList.content || !state.formList.isImportant) {
        ElMessage({
          message: '请完善相关内容',
          type: "success",
        });
        return
      }
      addOrEditUnusual()
    };
    const addOrEditUnusual = () => {
      let flag = state.formList.datetime.toString().includes('标准')
      let url = state.title === '新增异动信息' ? "/cts/back/trading/addUnusual" : "/cts/back/trading/editUnusual"
      let params = {
        content: state.formList.content,
        datetime: flag ? state.value + ' ' + formatTime(state.formList.datetime) : state.formList.datetime,
        id: state.title === '新增异动信息' ? '' : state.formList.id,
        isImportant: state.formList.isImportant,
        reason: state.formList.reason
      }
      proxy.$post(url, params).then((res) => {
        if (res.resultState === "200") {
          ElMessage({
            message: `${res.msg}`,
            type: "success",
          });
          state.dialogVisible = !state.dialogVisible;
          getList();
        }
      })
        .catch((err) => { });
    };
    const deleteOperate = (data) => {
      ElMessageBox.alert("确认删除该条数据吗?", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      }).then(() => {
        let params = {
          id: data.id,
        };
        proxy.$post("/cts/back/trading/deleteUnusual", params).then((res) => {
          if (res.resultState === "200") {
            ElMessage({
              message: `${res.msg}`,
              type: "success",
            });
            getList();
          }
        })
          .catch((err) => { });
      })
        .catch(() => { });
      return;
    };
    return {
      ...toRefs(state),
      handleSizeChange,
      handleCurrentChange,
      deleteOperate,
      confirmNode,
      addOrEdit,
      form
    };
  },
};
</script>

<style lang="scss" scoped>
.container-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}
.title {
  font-weight: bold;
}
.list-container {
  margin-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    &__title {
      font-weight: bold;
    }
    &__btn {
      background: rgb(56, 162, 138);
      width: 110px;
      padding: 8px 0;
      border-radius: 6px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .content {
    margin-top: 15px;
    .page {
      float: right;
      margin: 20px;
    }
  }
}
::v-deep .el-dialog {
  padding-bottom: 40px;
}
::v-deep .el-dialog__body {
  padding: 20px 30px;
  padding-bottom: 0;
  .stockInfo {
    display: flex;
  }
  .stockState {
    color: #38a28a;
    font-weight: bold;
  }
  .stock-form {
    width: 400px;
  }
}
::v-deep .el-dialog__footer {
  padding: 10px 30px;
  text-align: left;
}
</style>
